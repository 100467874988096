import React, { Component } from "react";
import Sidebar from "../layout/Sidebar";
import { connect } from "react-redux";
import FormErrors from "../FormErrors";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { Paper } from "@material-ui/core";
import config from "../../config";
import ReCAPTCHA from "react-google-recaptcha";

class Home extends Component {
  state = {
    learner: "",
    award: "",
    captchaResponse: "",
    errors: {
      cognito: null,
      blankfield: false,
    },
    isCaptchaVerified: false, // Added state to track captcha verification
  };

  handleCaptchaChange = (response) => {
    this.setState({
      captchaResponse: response,
      isCaptchaVerified: true, // Update captcha verification state
    });
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    console.log(this.state.award_number);
    console.log(this.state.learner);
    var path =
      "/award?name=" +
      this.state.learner +
      "&awardid=" +
      this.state.award_number +
      "&captcha=" +
      this.state.captchaResponse;
    console.log(path);
    this.props.history.push(path);
  };

  render() {
    const { isCaptchaVerified } = this.state; // Retrieve captcha verification state

    return (
      <div className="page">
        <Sidebar auth={this.props.auth} />
        <div className="fullPage">
          <div className="container--narrow">
            <Paper>
              <div className="loginFormWrapper">
                <div className="loginDarkMessage">Verify certificate</div>
                <FormErrors formerrors={this.state.errors} />

                <form onSubmit={this.handleSubmit}>
                  <div className="field">
                    <div className="control">
                      <TextField
                        required
                        className="input"
                        variant="outlined"
                        label="Award ID"
                        type="text"
                        id="award_number"
                        aria-describedby="userNameHelp"
                        placeholder="Enter Award ID"
                        value={this.state.award_number}
                        onChange={this.onInputChange}
                        fullWidth
                      />
                    </div>
                  </div>
                  <p></p>
                  <div className="field" style={{ marginBottom: "15px" }}>
                    <div className="control">
                      <TextField
                        required
                        className="input"
                        variant="outlined"
                        label="Firstname or Surname"
                        type="text"
                        id="learner"
                        aria-describedby="userNameHelp"
                        placeholder="Enter Firstname or Surname"
                        value={this.state.learner}
                        onChange={this.onInputChange}
                        fullWidth
                      />
                    </div>
                  </div>
                  <ReCAPTCHA
                    sitekey={config.captcheSiteKey}
                    onChange={this.handleCaptchaChange}
                  />
                  <p></p>
                  <div className="field">
                    <p className="control">
                      <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        disabled={!isCaptchaVerified} // Disable submit button if captcha is not verified
                      >
                        Verify
                      </Button>
                    </p>
                  </div>
                  {
                    <div className="loginLightMessage">
                      Please enter the Award ID and first name or surname as
                      shown on an ABMA Education issued certificate. The name
                      field is case sensitive, you will need to ensure that the
                      first name or surname is entered exactly as it appears on
                      the certificate. Please note that if the certificate does
                      not carry an Award ID, you will need to contact ABMA
                      Education directly via email (info@abma.uk.com) with a
                      copy of the certificate so that we can verify this for
                      you. If the verification form does not return a result, or
                      if you have any queries, please contact us via email
                      (info@abma.uk.com).
                    </div>
                  }
                </form>
              </div>
            </Paper>
          </div>
        </div>
      </div>
    );
  }

  onInputChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value,
    });
    document.getElementById(event.target.id).classList.remove("is-danger");
  };
}

const mapStateToProps = () => ({});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Home);
