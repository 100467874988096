import React, { Component } from "react";
import { connect } from "react-redux";
import configureStore from "../../store/configureStore";
import { get_award_details } from "../../actions/action-award";
import Loader from "../common/Loader";
import Sidebar from "../layout/Sidebar";
import logo from "../assets/images/abmalogo2.jpg";

const store = configureStore();

class Award extends Component {
  constructor() {
    super();
    this.state = {
      award: {},
    };
    this.onInputChange = this.onInputChange.bind(this);
  }

  componentDidMount() {
    console.log("componentDidMount");
    this.fetchDetails();
  }

  componentDidUpdate() {
    console.log("componentDidUpdate");
  }

  async fetchDetails() {
    const urlParams = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    });
    // Get the value of keys in the url- eg "https://example.com/?name=ABC&awardid=1123"
    let name = urlParams.name; // "ABC"
    let awardid = urlParams.awardid; // "1123"
    console.log("awardid", awardid);
    console.log("name", name);

    const { get_award_details } = this.props;
    await get_award_details(name, awardid);
    const { award } = store.getState().award;
    console.log("award", award);

    this.setState({
      award: award,
    });
  }

  onInputChange(event) {
    let newOrgunitDetails = this.state.OrgunitDetails;
    newOrgunitDetails.data[event.target.id] = event.target.value;
    this.setState({
      OrgunitDetails: newOrgunitDetails,
    });
  }

  async handleSubmit() {
    // event.preventDefault();
    // console.log("State  : ", this.state);
    const path = window.location.pathname.split("/");
    const id = path[path.length - 2];
    const { update_Orgunit } = this.props;
    await update_Orgunit(this.state.OrgunitDetails, id);
  }

  render() {
    const { showGlobalLoader } = store.getState().award;
    const { award } = store.getState().award;
    console.log("award to render", award);

    return (
      <div className="page">
        <Sidebar auth={this.props.auth} />
        {showGlobalLoader ? (
          <Loader />
        ) : (
          <div className="container">
            <div class="AwardWrapperWithBg">
              <div className="AwardWrapper">
                <div class="AwardMainPane">
                  <img src={logo} class="AwardHeadingLogo" alt="" width="150" />
                  <div class="AwardHeading"></div>
                  <div class="AwardSubHeading">VERIFICATION of Achievement</div>
                  <div>
                    {award.data ? (
                      <div>
                        <div class="AwardData">
                          <span class="AwardLabel">NAME: </span>
                          <span class="AwardPerson">{award.data.Learner}</span>
                        </div>
                        <div class="AwardData">
                          <span class="AwardLabel">QUALIFICATION: </span>
                          <span class="AwardPerson">
                            {award.data["Qual name"]}
                          </span>
                        </div>
                        <div class="AwardData">
                          <span class="AwardLabel">LEVEL: </span>
                          <span class="AwardPerson">
                            {award.data["Qual type"]}
                          </span>
                        </div>
                        <div class="AwardData">
                          <span class="AwardLabel">AWARD ID: </span>
                          <span class="AwardPerson">
                            {award.data["Award ID"]}
                          </span>
                        </div>
                        <div class="AwardData">
                          <span class="AwardLabel">GRADE: </span>
                          <span class="AwardPerson">{award.data.Grade}</span>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  award: state.award,
});

const mapDispatchToProps = (dispatch) => ({
  get_award_details: (name, awardid) =>
    dispatch(get_award_details(name, awardid)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Award);
