import React, { useState } from "react";
import { connect } from "react-redux";
import configureStore from "../../store/configureStore";
import {
  document_upload_requested,
  create_document,
} from "../../actions/action-document";
import TextField from "@material-ui/core/TextField";
import Loader from "../common/Loader";

import axios from "axios";

const store = configureStore();

function DocumentUploader(props) {
  const { rerender } = props;
  const [file, setFile] = useState();
  const [details, setDetails] = useState({
    title: "",
  });

  const onFileChange = (event) => {
    console.log("File change");
    setFile(event.target.files[0]);
  };

  const onInputChange = (event) => {
    // console.log("Input change", event.target.id)
    setDetails({ [event.target.id]: event.target.value });
  };

  const onFileUpload = async () => {
    console.log(`onFileUpload`);
    if (file === undefined) {
      return;
    }
    if (file.type !== "text/csv") {
      return;
    }
    console.log("file.type***************", file.type);
    await store.dispatch(document_upload_requested());
    const { apiEndpoint, credentials } = store.getState().jwt.api;
    if (apiEndpoint && credentials) {
      const fileName = file.name;
      const type = file.type;
      const method = "get";
      const jwt = `${credentials.idToken.jwtToken}`;
      const url = `${apiEndpoint}/document?getUploadSignedUrl=true&file=${fileName}&contentType=${type}&prefix=csvuploads`;
      const axiosHeader = {
        headers: {
          Authorization: jwt,
        },
      };
      return axios[method](url, axiosHeader)
        .then(async (response) => {
          console.log("signed url get response : ", response);
          await uploadFile(response);
        })
        .catch((err) => {
          console.log("signed url get failed : ", err);
        });
    }
  };

  const uploadFile = async (signedUrlData) => {
    console.log(`uploadFile`);
    const { apiEndpoint, credentials } = store.getState().jwt.api;
    if (apiEndpoint && credentials) {
      const method = "put";
      const url = signedUrlData.data.uploadURL;
      const body = file;
      const axiosHeader = {
        headers: {
          "Content-type": file.type,
        },
      };
      return axios[method](url, body, axiosHeader)
        .then(async (response) => {
          console.log("s3 upload response : ", response);
          var payload = {
            name: file.name,
            key: signedUrlData.data.Key,
          };
          await store.dispatch(create_document(payload));
          return rerender();
        })
        .catch((err) => {
          console.log("s3 upload failed : ", err);
        });
    }
  };

  const { showUploadLoader } = store.getState().document;

  return (
    <div>
      <h3>File Upload</h3>
      <div className="form-item-wrapper">
        <input type="file" onChange={onFileChange} />
      </div>
      <div>
        <button onClick={onFileUpload}>Upload</button>
      </div>
      {showUploadLoader ? <Loader /> : ""}
    </div>
  );
}

const mapStateToProps = (state) => ({
  document: state.document,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(DocumentUploader);
