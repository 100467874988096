import React, { Component } from "react";
import { connect } from "react-redux";
import configureStore from "../../../store/configureStore";
import CommonSnackBar from "../../common/CommonSnackBar";
import { get_all_documents } from "../../../actions/action-document";
import { DataGrid } from "@material-ui/data-grid";
import { timeFormat } from "../../common/CommonFunctions";
import DocumentDetails from "./DocumentDetails";
import Button from "@material-ui/core/Button";
import DocumentUploader from "../../common/DocumentUploader";
import Loader from "../../common/Loader";
import { Paper } from "@material-ui/core";
import Sidebar from "../../layout/Sidebar";

const store = configureStore();

const workColumns = [
  {
    field: "name",
    headerName: "Name",
    width: 200,
    editable: false,
  },
  {
    field: "uploadedBy",
    headerName: "Uploaded By",
    width: 150,
    editable: false,
  },
  {
    field: "uploaded_Date",
    headerName: "Uploaded Date",

    width: 200,
    editable: false,
    valueGetter: (params) =>
      `${timeFormat(params.getValue(params.id, "uploadedDate")) || ""}`,
  },
  {
    field: "processing_status",
    headerName: "Processing Status",
    width: 250,
    editable: false,
  },
];

class DocumentBoard extends Component {
  constructor() {
    super();
    this.state = {
      documentListToShow: [],
      tabValue: 0,
      showDocumentDetails: false,
      currentDocument: {},
      upload: false,
      selectionModel: [],
    };
  }

  componentDidMount() {
    console.log("componentDidMount.. called");
    this.fetchDetails();
  }

  async fetchDetails() {
    const { get_all_documents } = this.props;
    await get_all_documents();
    const { documents } = store.getState().document;
    this.setState({
      documentListToShow: documents,
    });
  }

  componentWillUnmount() {}

  componentDidUpdate() {
    console.log("componentDidUpdate");
  }

  renderDocument(documents) {
    let newRows = [];
    documents.map((document, index) => {
      let newRow = document.data;
      newRow["id"] = document.data.identifier;
      newRow["processing_status"] = document.Processing_Status;
      newRows.push(newRow);
    });
    return newRows;
  }

  openDetails(e) {
    console.log("openDetails");
    this.setState({
      showDocumentDetails: true,
      currentDocument: e.row,
      selectionModel: e.row.id,
    });
  }

  handleUploadBox() {
    console.log("handleUploadBox");
    this.setState((prevState) => ({
      upload: !prevState.upload,
    }));
  }

  rerender = () => {
    this.fetchDetails();
  };

  closeDetails() {
    console.log("closeDetails");
    this.setState({
      showDocumentDetails: false,
      currentDocument: {},
    });
  }

  setSelectionModel(selection) {
    this.setState({
      selectionModel: selection,
    });
  }

  render() {
    console.log("render called");
    console.log("store.getState()", store.getState());

    const { showGlobalLoader } = store.getState().document;
    return (
      <div className="page">
        <Sidebar auth={this.props.auth} />
        <div className="container">
          <section className="section admin-upload-section">
            <div>
              <div>
                <DocumentUploader
                  rerender={this.rerender}
                  defaultState={this.state.upload}
                  handleClose={() => {
                    this.handleUploadBox();
                  }}
                />
              </div>
              {showGlobalLoader ? (
                <Loader />
              ) : this.state.documentListToShow.length !== 0 ? (
                <Paper
                  style={{ height: 500 }}
                  className={`formList ${
                    this.state.showDocumentDetails ? "collapsed" : ""
                  }`}
                >
                  <DataGrid
                    rows={this.renderDocument(this.state.documentListToShow)}
                    columns={workColumns}
                    pageSize={15}
                    checkboxSelection
                    disableSelectionOnClick
                    onCellClick={(e) => {
                      this.openDetails(e);
                    }}
                    selectionModel={this.state.selectionModel}
                    hideFooterSelectedRowCount
                    onSelectionModelChange={(selection) => {
                      if (selection.length > !2) {
                        let lastElement = selection.pop();
                        this.setSelectionModel(lastElement);
                      } else {
                        let lastElement = selection.pop();
                        this.setSelectionModel(lastElement);
                      }
                    }}
                  />
                </Paper>
              ) : (
                "No Documents....."
              )}
              <div>
                {this.state.showDocumentDetails && (
                  <DocumentDetails
                    documentDetails={this.state.currentDocument}
                    closeDetails={() => {
                      this.closeDetails();
                    }}
                  />
                )}
              </div>
              <CommonSnackBar />
            </div>
          </section>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  permission: state.permission,
  document: state.document,
});

const mapDispatchToProps = (dispatch) => ({
  get_all_documents: () => dispatch(get_all_documents()),
});

export default connect(mapStateToProps, mapDispatchToProps)(DocumentBoard);
