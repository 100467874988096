import React from "react";
import { connect } from "react-redux";
import configureStore from "../../../store/configureStore";
import { timeFormat, downloadFromUrl } from "../../common/CommonFunctions";
import Button from "@material-ui/core/Button";
import axios from "axios";

const store = configureStore();

function DocumentDetails(props) {
  const { documentDetails, closeDetails } = props;

  const renderDetails = (details) => {
    return Object.keys(details).map(function (keyName, keyIndex) {
      return (
        <div key={keyName} className="detailsItem">
          <div className="detailsKey">
            {keyName} {" : "}
          </div>
          {keyName === "lastModifiedDate" || keyName === "createdDate" ? (
            <div className="detailsValue">{timeFormat(details[keyName])}</div>
          ) : (
            <div className="detailsValue">{details[keyName]}</div>
          )}
        </div>
      );
    });
  };

  const getDownloadUrl = async (key) => {
    const { apiEndpoint, credentials } = store.getState().jwt.api;
    if (apiEndpoint && credentials) {
      const method = "get";
      const jwt = `${credentials.idToken.jwtToken}`;
      const url = `${apiEndpoint}/document?getDownloadSignedUrl=true&file=${key}`;
      const axiosHeader = {
        headers: {
          Authorization: jwt,
        },
      };
      return axios[method](url, axiosHeader)
        .then(async (response) => {
          console.log("signed url get response : ", JSON.stringify(response));
          console.log(
            "signed url download : ",
            JSON.stringify(response.data.getURL)
          );
          await downloadFromUrl(response.data.getURL, documentDetails.name);
        })
        .catch((err) => {
          console.log("signed url get failed : ", err);
        });
    }
  };

  function getSignedUrl(key) {
    const { apiEndpoint, credentials } = store.getState().jwt.api;
    if (apiEndpoint && credentials) {
      const method = "get";
      const jwt = `${credentials.idToken.jwtToken}`;
      const url = `${apiEndpoint}/document?getDownloadSignedUrl=true&file=${key}`;
      const axiosHeader = {
        headers: {
          Authorization: jwt,
        },
      };
      return axios[method](url, axiosHeader)
        .then(async (response) => {
          console.log("signed url get response : ", JSON.stringify(response));
          console.log(
            "signed url string : ",
            JSON.stringify(response.data.getURL)
          );
          return response.data.getURL;
        })
        .catch((err) => {
          console.log("signed url get failed : ", err);
          return "";
        });
    }
  }

  return (
    <div className="detailsDrawer">
      <Button
        onClick={() => {
          closeDetails();
        }}
        variant="contained"
      >
        Close
      </Button>

      <div>{renderDetails(documentDetails)}</div>
      <Button
        onClick={() => {
          getDownloadUrl(documentDetails.key);
        }}
        variant="contained"
        color="primary"
      >
        Download
      </Button>
    </div>
  );
}

const mapStateToProps = (state) => ({
  permission: state.permission,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(DocumentDetails);
