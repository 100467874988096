export const RESET_STATE = "RESET_STATE";

export const SET_ALL_PERMISSIONS = "SET_ALL_PERMISSIONS";
export const PERMISSIONS_HAS_ERRORED = "PERMISSIONS_HAS_ERRORED";
export const SET_ACTIVE_TEAMID = "SET_ACTIVE_TEAMID";
export const SET_ACTIVE_OUID = "SET_ACTIVE_OUID";
export const SET_USER_ROLES = "SET_USER_ROLES";
export const SET_ACTIVE_OUID_CHAIN = "SET_ACTIVE_OUID_CHAIN";
export const CLEAR_ACTIVE_OUID_CHAIN = "CLEAR_ACTIVE_OUID_CHAIN";

export const LOGIN_REQUSTED = "LOGIN_REQUSTED";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILED = "LOGIN_FAILED";

export const JWT_SET = "JWT_SET";
export const UN_JWT_SET = "UN_JWT_SET";
export const JWT_ERRORED = "JWT_ERRORED";

export const SHOW_SNACKBAR = "SHOW_SNACKBAR";
export const HIDE_SNACKBAR = "HIDE_SNACKBAR";

export const GET_AWARD_DETAILS_REQUESTED = "GET_AWARD_DETAILS_REQUESTED";
export const GET_AWARD_DETAILS_SUCCESS = "GET_AWARD_DETAILS_SUCCESS";
export const GET_AWARD_DETAILS_ERRORED = "GET_AWARD_DETAILS_ERRORED";

export const GET_MY_PROFILE_DETAILS_REQUESTED =
  "GET_MY_PROFILE_DETAILS_REQUESTED";
export const GET_MY_PROFILE_DETAILS_SUCCESS = "GET_MY_PROFILE_DETAILS_SUCCESS";
export const GET_MY_PROFILE_DETAILS_ERRORED = "GET_MY_PROFILE_DETAILS_ERRORED";

export const CREATE_DOCUMENT_REQUESTED = "CREATE_DOCUMENT_REQUESTED";
export const CREATE_DOCUMENT_SUCCESS = "CREATE_DOCUMENT_SUCCESS";
export const CREATE_DOCUMENT_ERRORED = "CREATE_DOCUMENT_ERRORED";

export const GET_ALL_DOCUMENTS_REQUESTED = "GET_ALL_DOCUMENT_REQUESTED";
export const GET_ALL_DOCUMENTS_SUCCESS = "GET_ALL_DOCUMENT_SUCCESS";
export const GET_ALL_DOCUMENTS_ERRORED = "GET_ALL_DOCUMENT_ERRORED";

export const DOCUMENT_UPLOAD_REQUESTED = "DOCUMENT_UPLOAD_REQUESTED";
export const DOCUMENT_UPLOAD_SUCCESS = "DOCUMENT_UPLOAD_SUCCESS";
export const DOCUMENT_UPLOAD_ERRORED = "DOCUMENT_UPLOAD_ERRORED";

export const DELETE_DOCUMENT_REQUESTED = "DELETE_DOCUMENT_REQUESTED";
export const DELETE_DOCUMENT_SUCCESS = "DELETE_DOCUMENT_SUCCESS";
export const DELETE_DOCUMENT_ERRORED = "DELETE_DOCUMENT_ERRORED";
