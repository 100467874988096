const dev = {
  api: {
    invokeUrl: "https://mhf7y133pe.execute-api.eu-west-2.amazonaws.com/prod",
  },
  cognito: {
    REGION: "us-west-2",
    USER_POOL_ID: "eu-west-2_k6GHkDN4o",
    APP_CLIENT_ID: "2b9thtbc45bkevb7l28kgkub1e",
  },
  captcheSiteKey: "6LfgumApAAAAAKbfW8btZGr3RgwTkFzz5fHP5gT2",
};

const test = {
  api: {
    invokeUrl: "https://mhf7y133pe.execute-api.eu-west-2.amazonaws.com/prod",
  },
  cognito: {
    REGION: "us-west-2",
    USER_POOL_ID: "eu-west-2_k6GHkDN4o",
    APP_CLIENT_ID: "2b9thtbc45bkevb7l28kgkub1e",
  },
  captcheSiteKey: "6LfgumApAAAAAKbfW8btZGr3RgwTkFzz5fHP5gT2",
};

const prod = {
  api: {
    invokeUrl: "https://mhf7y133pe.execute-api.eu-west-2.amazonaws.com/prod",
  },
  cognito: {
    REGION: "us-west-2",
    USER_POOL_ID: "eu-west-2_k6GHkDN4o",
    APP_CLIENT_ID: "2b9thtbc45bkevb7l28kgkub1e",
  },
  captcheSiteKey: "6LfgumApAAAAAKbfW8btZGr3RgwTkFzz5fHP5gT2",
};

let config = dev;
if (process.env.REACT_APP_STAGE) {
  switch (process.env.REACT_APP_STAGE.trim()) {
    case "dev":
      config = dev;
      break;
    case "test":
      config = test;
      break;
    case "prod":
      config = prod;
      break;
    default:
      config = dev;
      break;
  }
}

export default {
  // Add common config values here
  MAX_ATTACHMENT_SIZE: 5000000,
  ...config,
};
