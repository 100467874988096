import {
  GET_AWARD_DETAILS_REQUESTED,
  GET_AWARD_DETAILS_SUCCESS,
  GET_AWARD_DETAILS_ERRORED,
} from "../constant/action-types";
import axios from "axios";
import { showSnackbar } from "./action-snackbar";

import configureStore from "../store/configureStore";

const store = configureStore();

export const get_award_details_requested = () => ({
  type: GET_AWARD_DETAILS_REQUESTED,
});

export const get_award_details_success = (payload) => ({
  type: GET_AWARD_DETAILS_SUCCESS,
  payload,
});

export const get_award_profile_details_errored = (error) => ({
  type: GET_AWARD_DETAILS_ERRORED,
  error,
});

export const get_award_details = (name, awardid) => async (dispatch) => {
  dispatch(get_award_details_requested());
  console.log(`get_award_details`, awardid);
  const { apiEndpoint } = store.getState().jwt.api;
  if (apiEndpoint) {
    let url;
    console.log(`url : ${url}`);
    const method = "get";
    const axiosHeader = {};
    url = `${apiEndpoint}/award?name=${name}&awardId=${awardid}`;
    return axios[method](url, axiosHeader)
      .then((response) => {
        console.log("Get Award Details Success");
        dispatch(get_award_details_success(response.data));
      })
      .catch((err) => {
        console.log("Get Award Details Has Errored");
        console.error("ErrorLog : ", JSON.stringify(err));
        dispatch(get_award_profile_details_errored(err));
      });
  }
  return null;
};
