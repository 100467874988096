import {
  CREATE_DOCUMENT_REQUESTED,
  CREATE_DOCUMENT_SUCCESS,
  CREATE_DOCUMENT_ERRORED,
  GET_ALL_DOCUMENTS_REQUESTED,
  GET_ALL_DOCUMENTS_SUCCESS,
  GET_ALL_DOCUMENTS_ERRORED,
  DOCUMENT_UPLOAD_REQUESTED,
  DOCUMENT_UPLOAD_SUCCESS,
  DOCUMENT_UPLOAD_ERRORED,
  DELETE_DOCUMENT_REQUESTED,
  DELETE_DOCUMENT_SUCCESS,
  DELETE_DOCUMENT_ERRORED,
} from "../constant/action-types";
import axios from "axios";
import { showSnackbar } from "./action-snackbar";

import configureStore from "../store/configureStore";

const store = configureStore();

export const document_upload_requested = () => ({
  type: DOCUMENT_UPLOAD_REQUESTED,
});

export const document_upload_success = () => ({
  type: DOCUMENT_UPLOAD_SUCCESS,
});

export const document_upload_errored = (error) => ({
  type: DOCUMENT_UPLOAD_ERRORED,
  error,
});

export const create_document_requested = () => ({
  type: CREATE_DOCUMENT_REQUESTED,
});

export const create_document_success = () => ({
  type: CREATE_DOCUMENT_SUCCESS,
});

export const create_document_errored = (error) => ({
  type: CREATE_DOCUMENT_ERRORED,
  error,
});

export const delete_document_requested = () => ({
  type: DELETE_DOCUMENT_REQUESTED,
});

export const delete_document_success = () => ({
  type: DELETE_DOCUMENT_SUCCESS,
});

export const delete_document_errored = (error) => ({
  type: DELETE_DOCUMENT_ERRORED,
  error,
});

export const create_document = (payload) => async (dispatch) => {
  dispatch(create_document_requested());
  console.log(`create_document`);
  const { apiEndpoint, credentials } = store.getState().jwt.api;
  if (apiEndpoint && credentials) {
    const jwt = `${credentials.idToken.jwtToken}`;
    const url = `${apiEndpoint}/document`;
    const method = "post";
    const axiosHeader = {
      headers: {
        Authorization: jwt,
      },
    };
    return axios[method](url, payload, axiosHeader)
      .then((response) => {
        // console.log("response : ",JSON.stringify(response));
        dispatch(showSnackbar("Document created Successfully", "success"));
        dispatch(create_document_success());
      })
      .catch((err) => {
        console.log("Document creation Failed");
        // console.error('ErrorLog : ', JSON.stringify(err));
        dispatch(showSnackbar("Document creation Failed", "error"));
        dispatch(create_document_errored(err));
      });
  }
  return null;
};

export const get_all_documents_requested = () => ({
  type: GET_ALL_DOCUMENTS_REQUESTED,
});

export const get_all_documents_success = (payload) => ({
  type: GET_ALL_DOCUMENTS_SUCCESS,
  payload,
});

export const get_all_documents_errored = (error) => ({
  type: GET_ALL_DOCUMENTS_ERRORED,
  error,
});

export const get_all_documents = () => async (dispatch) => {
  dispatch(get_all_documents_requested());
  console.log(`get_all_documents`);
  const { apiEndpoint, credentials } = store.getState().jwt.api;
  if (apiEndpoint && credentials) {
    const jwt = `${credentials.idToken.jwtToken}`;
    let url = `${apiEndpoint}/document`;
    const method = "get";
    const axiosHeader = {
      headers: {
        Authorization: jwt,
      },
    };
    return axios[method](url, axiosHeader)
      .then(async (response) => {
        console.log("Get All Documents Successfully");
        console.log("Get All Documents Successfully response", response);
        for (let index = 0; index < response.data.items.length; index++) {
          const element = response.data.items[index];
          var signedUrl = await get_document_signed_url(element.data.key);
          console.log("*********signedURL", signedUrl);
          element["SignedUrl"] = signedUrl;
        }
        dispatch(get_all_documents_success(response.data));
      })
      .catch((err) => {
        console.log("Get All Documents Failed");
        // console.error('ErrorLog : ', JSON.stringify(err));
        if (err.response) {
          var data = err.response.data;
          dispatch(showSnackbar(data.error, "error"));
        } else {
          dispatch(showSnackbar("Get All Documents Failed", "error"));
        }
        dispatch(get_all_documents_errored(err));
      });
  }
  return null;
};

const get_document_signed_url = async (key) => {
  console.log(`get_document_signed_url_requested->key`, key);
  const { apiEndpoint, credentials } = store.getState().jwt.api;
  if (apiEndpoint && credentials) {
    const jwt = `${credentials.idToken.jwtToken}`;
    let url = `${apiEndpoint}/document/document?getDownloadSignedUrl=true&file=${key}`;
    const method = "get";
    const axiosHeader = {
      headers: {
        Authorization: jwt,
      },
    };
    return axios[method](url, axiosHeader)
      .then((response) => {
        console.log("get_document_signed_url response", response);
        return response.data.getURL;
      })
      .catch((err) => {
        console.log("get_document_signed_url Failed");
      });
  }
  return null;
};
