import React, { Component } from "react";
import { connect } from "react-redux";
import FormErrors from "../FormErrors";
import Validate from "../utility/FormValidation";
import { Auth } from "aws-amplify";
import { setJwt } from "../../actions/action-jwt";
import { setAllPermissions } from "../../actions/action-permissions";
import {
  login_requested,
  login_success,
  login_failed,
} from "../../actions/action-auth";
import configureStore from "../../store/configureStore";
import jwt from "jwt-decode";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Loader from "../common/Loader";
import { Paper } from "@material-ui/core";

const store = configureStore();

class LogIn extends Component {
  state = {
    username: "",
    password: "",
    redirection: "",
    errors: {
      cognito: null,
      blankfield: false,
    },
  };

  componentDidMount() {
    this.checkRedirection();
  }

  checkRedirection() {
    const urlParams = new URLSearchParams(window.location.search);
    const redirection = urlParams.get("redirect");
    if (redirection !== null) {
      console.log("redirection : ", redirection);
      this.setState({
        redirection: redirection,
      });
    }
  }

  clearErrorState = () => {
    this.setState({
      errors: {
        cognito: null,
        blankfield: false,
      },
    });
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    // Form validation
    this.clearErrorState();
    const error = Validate(event, this.state);
    if (error) {
      this.setState({
        errors: { ...this.state.errors, ...error },
      });
    }

    // AWS Cognito integration here
    try {
      store.dispatch(login_requested());
      const user = await Auth.signIn(this.state.username, this.state.password);
      store.dispatch(login_success());
      console.log("currentSession user", user);
      this.props.auth.setAuthStatus(true);
      this.props.auth.setUser(user);
      const { setJwt } = this.props;
      await setJwt(user);
      const idToken = user.signInUserSession.idToken;
      const decodedToken = jwt(idToken.jwtToken);
      console.log("decodedToken", decodedToken);
      var groups = decodedToken["cognito:groups"];
      console.log("groups", groups);
      if (groups.indexOf("admin") > -1) {
        this.props.history.push(`/admin-home`);
      } else {
        this.props.history.push(`/`);
      }
    } catch (error) {
      await store.dispatch(login_failed());
      let err = null;
      !error.message ? (err = { message: error }) : (err = error);
      this.setState({
        errors: {
          ...this.state.errors,
          cognito: err,
        },
      });
    }
  };

  onInputChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value,
    });
    document.getElementById(event.target.id).classList.remove("is-danger");
  };

  render() {
    const { showGlobalLoader } = store.getState().auth_state;
    return (
      <div className="fullPage">
        <Paper>
          <div className="loginFormWrapper">
            <h1 className="loginHeading">Login</h1>
            <FormErrors formerrors={this.state.errors} />
            {showGlobalLoader ? <Loader /> : <div></div>}
            <form onSubmit={this.handleSubmit}>
              <div className="field">
                <div className="control">
                  <TextField
                    fullWidth
                    className="input"
                    variant="outlined"
                    label="User name"
                    type="text"
                    id="username"
                    aria-describedby="usernameHelp"
                    placeholder="Enter username"
                    value={this.state.username}
                    onChange={this.onInputChange}
                  />
                </div>
              </div>
              <p></p>
              <div className="field">
                <div className="control has-icons-left">
                  <TextField
                    fullWidth
                    className="input"
                    variant="outlined"
                    label="Password"
                    type="password"
                    id="password"
                    placeholder="Password"
                    value={this.state.password}
                    onChange={this.onInputChange}
                  />
                </div>
              </div>
              <p></p>
              <div className="field">
                <p className="control">
                  <a href="/forgotpassword">Forgot password?</a>
                </p>
              </div>
              <div className="field">
                <p className="control">
                  <Button variant="contained" color="primary" type="submit">
                    Login
                  </Button>
                </p>
              </div>
            </form>
            <div className="field">
              <p className="control">
                New User? <a href="/register">Sign Up</a>
              </p>
            </div>
          </div>
        </Paper>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth_state: state.auth_state,
});

const mapDispatchToProps = (dispatch) => ({
  setJwt: (user) => dispatch(setJwt(user)),
  setAllPermissions: (policies) => dispatch(setAllPermissions(policies)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LogIn);
