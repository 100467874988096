import React, { Component } from "react";
import { connect } from "react-redux";
import { Router, Switch, Route } from "react-router-dom";
import "./App.css";

// Authentication
import { Auth } from "aws-amplify";
import LogIn from "./components/auth/LogIn";
import Register from "./components/auth/Register";
import ForgotPassword from "./components/auth/ForgotPassword";
import ForgotPasswordVerification from "./components/auth/ForgotPasswordVerification";
import ChangePassword from "./components/auth/ChangePassword";
import ChangePasswordConfirm from "./components/auth/ChangePasswordConfirm";

// Home
import Home from "./components/pages/Home";

// Profile
import MyProfile from "./components/pages/MyProfile";
import Welcome from "./components/auth/Welcome";

import { setJwt } from "../src/actions/action-jwt";
import { setAllPermissions } from "../src/actions/action-permissions";
import { history } from "./reducers";

import DocumentBoard from "./components/pages/Document/DocumentBoard";
import Award from "./components/pages/Award";

import {
  userRoleCheck,
  permissionsCheck,
} from "../src/components/common/CommonFunctions";
import configureStore from "../src/store/configureStore";

import {
  setActiveOuid,
  setActiveOuidChain,
} from "../src/actions/action-permissions";

const store = configureStore();

class App extends Component {
  state = {
    isAuthenticated: false,
    isAuthenticating: true,
    user: null,
  };

  setAuthStatus = (authenticated) => {
    this.setState({ isAuthenticated: authenticated });
  };

  setUser = (user) => {
    this.setState({ user: user });
  };

  async componentDidMount() {
    // console.log("StageEnv : ", process.env);
    // console.log("StageEnvConfig : ", config);
    try {
      const session = await Auth.currentSession();
      this.setAuthStatus(true);
      console.log("currentSession in componentDidMount", session);
      const user = await Auth.currentAuthenticatedUser();
      console.log("user in componentDidMount", user);
      this.setUser(user);
      const { setJwt } = this.props;
      await setJwt(user);
      // jwt refresh intervel...........
      this.refreshJwt();
    } catch (error) {
      if (error !== "No current user") {
        console.log(error);
      }
    }
    this.setState({ isAuthenticating: false });
  }

  async refreshJwt() {
    console.log("refreshJwt");
    try {
      let intervalId = setInterval(async () => {
        const session = await Auth.currentSession();
        this.setAuthStatus(true);
        console.log("currentSession", session);
        const user = await Auth.currentAuthenticatedUser();
        console.log("currentSession user", user);
        this.setUser(user);
        const { setJwt } = this.props;
        await setJwt(user);
      }, 60000);
      this.setState({ intervalId: intervalId });
    } catch (e) {
      console.log("setInterval error :", e);
    }
  }

  componentWillUnmount() {
    console.log("APP unmounted..");
    clearInterval(this.state.intervalId);
  }

  render() {
    const authProps = {
      isAuthenticated: this.state.isAuthenticated,
      user: this.state.user,
      setAuthStatus: this.setAuthStatus,
      setUser: this.setUser,
    };
    return (
      !this.state.isAuthenticating && (
        <div className="App">
          <Router history={history}>
            <div>
              <Switch>
                <Route
                  exact
                  path="/"
                  render={(props) => <Home {...props} auth={authProps} />}
                />

                {/* Auth */}

                <Route
                  exact
                  path="/login"
                  render={(props) => <LogIn {...props} auth={authProps} />}
                />
                <Route
                  exact
                  path="/register"
                  render={(props) => <Register {...props} auth={authProps} />}
                />
                <Route
                  exact
                  path="/forgotpassword"
                  render={(props) => (
                    <ForgotPassword {...props} auth={authProps} />
                  )}
                />
                <Route
                  exact
                  path="/forgotpasswordverification"
                  render={(props) => (
                    <ForgotPasswordVerification {...props} auth={authProps} />
                  )}
                />
                <Route
                  exact
                  path="/changepassword"
                  render={(props) => (
                    <ChangePassword {...props} auth={authProps} />
                  )}
                />
                <Route
                  exact
                  path="/changepasswordconfirmation"
                  render={(props) => (
                    <ChangePasswordConfirm {...props} auth={authProps} />
                  )}
                />
                <Route
                  exact
                  path="/welcome"
                  render={(props) => <Welcome {...props} auth={authProps} />}
                />

                {/* User */}

                <Route
                  exact
                  path="/admin-home"
                  render={(props) => (
                    <DocumentBoard {...props} auth={authProps} />
                  )}
                />
                <Route
                  exact
                  path="/award"
                  render={(props) => <Award {...props} auth={authProps} />}
                />

                <Route
                  exact
                  path="/:id/my-profile"
                  render={(props) => <MyProfile {...props} auth={authProps} />}
                />
              </Switch>
            </div>
          </Router>
        </div>
      )
    );
  }
}

const mapStateToProps = (state) => ({
  permission: state.permission,
});

const mapDispatchToProps = (dispatch) => ({
  setJwt: (user) => dispatch(setJwt(user)),
  setAllPermissions: (policies) => dispatch(setAllPermissions(policies)),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
