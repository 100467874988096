import React from 'react';

export default function Welcome() {
  return (
    <div className="fullPageImage">
      <div className="container">
        <p>You have successfully registered a new account.</p>
        <p>Account administrator will verify your account and provide permissions</p>
      </div>
    </div>
  )
}
