import { createBrowserHistory } from "history";
import { connectRouter } from "connected-react-router";
import { combineReducers } from "redux";
import * as jwt from "./reducer-jwt";
import * as auth_state from "./reducer-auth";
import * as profile from "./reducer-myProfile";
import * as snackbar from "./reducer-snackbar";
import * as document from "./reducer-document";
import * as permission from "./reducer-permissions";

import * as award from "./reducer-award";
import { RESET_STATE } from "../constant/action-types";

import { cleanState } from "../utils";

export const history = createBrowserHistory();

const router = { router: connectRouter(history) };

const Reducers = Object.assign(
  router,
  jwt,
  auth_state,
  permission,
  snackbar,
  profile,
  award,
  document
);

const allReducers = combineReducers(Reducers);

const rootReducer = (state, action) => {
  let resultState = state;
  if (action.type === RESET_STATE) {
    console.log("Reset State");
    resultState = cleanState(resultState, [
      "router",
      "jwt",
      "auth_state",
      "permission",
      "config",
      "orgunit",
      "award",
      "snackbar",
      "profile",
      "userProfile",
      "orgUserProfile",
      "appUserProfile",
    ]);
  }
  return allReducers(resultState, action);
};

export default rootReducer;
