import {
  GET_AWARD_DETAILS_REQUESTED,
  GET_AWARD_DETAILS_SUCCESS,
  GET_AWARD_DETAILS_ERRORED,
} from "../constant/action-types";

const defaultState = {
  showGlobalLoader: false,
  award: {},
  error: {},
};

export function award(state = defaultState, action) {
  switch (action.type) {
    case GET_AWARD_DETAILS_REQUESTED:
      console.log("GET_AWARD_DETAILS_REQUESTED : ");
      return {
        ...state,
        showGlobalLoader: true,
        award: {},
      };

    case GET_AWARD_DETAILS_SUCCESS:
      return {
        ...state,
        showGlobalLoader: false,
        award: action.payload,
      };

    case GET_AWARD_DETAILS_ERRORED:
      return {
        ...state,
        showGlobalLoader: false,
        error: action.error,
      };

    default:
      return state;
  }
}
